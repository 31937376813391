<div class="row wizard-block">
    <div class="col-md-12">
        <div class="congratulations">
            <div class="title">{{'Congratulations!'|translate}}</div>
            <div>
                <span>{{'You have completed the platform activation.'|translate}}</span>
                <span>{{'Please check your inbox for further instructions.'|translate}}</span>
            </div>
        </div>
    </div>
</div>
