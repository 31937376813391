<div class="modal-header">
    <div class="modal-title">{{'Are you sure?' | translate}}</div>
    <button type="button" class="close btn" (click)="cancel()" [attr.aria-label]="'Close' | translate">
        <i class="fa-solid fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <span>{{'Before proceeding please read carefully the documentation at:' | translate}}</span>
    <a href="https://docs.globaleaks.org/en/main/security/EncryptionProtocol.html" rel="noreferrer" target="_blank">Encryption</a>
</div>
<div class="modal-footer">
    <button id="modal-action-ok" class="btn btn-primary" (click)="confirm()">
        <i class="fa-solid fa-check"></i> <span>{{'Yes' | translate}}</span>
    </button>
    <button id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
        <i class="fa-solid fa-times"></i> <span>{{'No' | translate}}</span>
    </button>
</div>