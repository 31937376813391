<span *ngIf="uploading">{{'Uploading files…'|translate}}</span>
<span *ngIf="!uploading && progress==100">{{'Upload completed successfully!'|translate}}</span>
<div id="FileUpoloadsInfo">
    <div *ngIf="estimatedTime && uploading">
        <span>{{'Estimated upload time:'|translate}}</span>
        <span *ngIf="isFinite(estimatedTime)">{{estimatedTime}}</span>
        <span>{{'seconds'|translate}}</span>
        <div class="progress progress-striped;active" *ngIf="progress">
           <div class="progress-bar" role="progressbar" [ngStyle]="{width: progress*105 + '%'}"></div>
        </div>
    </div>
</div><br>