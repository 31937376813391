<div class="modal-header">
    <div class="modal-title">{{ 'Attention' | translate }}</div>
    <button type="button" class="close btn" (click)="cancel()" [attr.aria-label]="'Close' | translate">
        <i class="fa-solid fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <label for="ReceiptCode">{{ 'For security reasons, the code needs to be changed' | translate }}.</label>
            <div class="mt-2 form-inline d-flex justify-content-center" title="{{'Copy to clipboard' | translate}}"
                (click)="utils.copyToClipboard(arg.receipt)">
                <div class="form-group d-block mx-auto">
                    <div class="input-group">
                        <input id="ReceiptCode" class="form-control text-center" type="text" maxlength="19" size="19"
                            [(ngModel)]="arg.formatted_receipt" name="receipt-input">
                        <span class="input-group-append">
                            <button id="ReceiptButton" (click)="utils.copyToClipboard(arg.receipt)" class="btn btn-primary rounded-start-0"
                                type="button" [attr.aria-label]="'Copy to clipboard' | translate">
                                <i class="fa-solid fa-copy"></i>
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button id="modal-action-ok" class="btn btn-primary" (click)="confirm()">
        <span>{{ 'Confirm' | translate }}</span>
    </button>
    <a id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
        <i class="fa-solid fa-times"></i>
        <span>{{ 'Close' | translate }}</span>
    </a>
</div>