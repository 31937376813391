<div id="SubmissionStepReceiver" class="receiverCards">
    <div id="title" *ngIf="!show_steps_navigation_bar">{{'Recipient selection'|translate}}</div>
    <div *ngIf="submission.optional_receivers">
        <div class="description mb-2"><strong>{{'Select the recipients of your report'|translate}}</strong></div>
        <div *ngIf="submission.context.maximum_selectable_receivers !== 0 && !submission.context.select_all_receivers" id="MaxSelectableReceiverBox">
            <div>
                <span>{{'Recipients selected:'|translate}}</span> {{submission.countSelectedReceivers()}} /{{submission.context.maximum_selectable_receivers}}
            </div>
            <div class="text-danger" *ngIf="submission.countSelectedReceivers() === submission.context.maximum_selectable_receivers">{{'You have reached the maximum number of selectable recipients.'|translate}}</div>
        </div>
        <div id="ReceiverSelectionBox" class="row">
            <div *ngFor="let receiver of submission.receivers | filter : 'forcefully_selected' : false | orderBy:receiversOrderPredicate; let index = index" id="receiver-{{index}}" [ngClass]="utilsService.getCardSize(submission.receivers.length)" (click)="switchSelection.emit(receiver)">
                <src-receiver-card [submission]="submission" [receiverModel]="receiver"></src-receiver-card>
            </div>
        </div>
    </div>
    <div *ngIf="submission.mandatory_receivers">
        <div class="description">{{'The following recipients will receive your report and could not be deselected:'|translate}}</div>
        <div id="ReceiverSelectionBox" class="row">
            <div *ngFor="let receiver of submission.receivers | filter : 'forcefully_selected' : true | orderBy:receiversOrderPredicate; let index = index" id="receiver-{{index}}" [ngClass]="utilsService.getCardSize(submission.receivers.length)" (click)="switchSelection.emit(receiver)">
                <src-receiver-card [submission]="submission" [receiverModel]="receiver"></src-receiver-card>
            </div>
        </div>
    </div>
</div>
