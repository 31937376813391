<div class="modal-header">
    <div class="modal-title">{{'Grant access'|translate}}</div>
    <button type="button" class="close btn" (click)="cancel()" [attr.aria-label]="'Close' | translate">
        <i class="fa-solid fa-times"></i>
    </button>
</div>
<div class="modal-body">
    <div id="ReceiverContextAdder" class="form-group">
        <div class="input-group">
            <ng-select class="w-25 rounded-end-0" [attr.data-cy]="'reciever_selection'" [(ngModel)]="receiver_id" [items]="selectableRecipients" bindLabel="name" placeholder="{{ 'Search' | translate }}" [searchable]="true">
                <ng-template ng-label-tmp let-item="item">
                    <span>{{ item.name }}</span>
                </ng-template>
            </ng-select>
            <div class="input-group-append">
                <span class="input-group-text h-100">
                    <i class="fa-solid fa-search"></i>
                </span>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button id="modal-action-ok" class="btn btn-primary" (click)="confirm()">
        <span>{{'Confirm' | translate}}</span>
    </button>
    <a id="modal-action-cancel" class="btn btn-outline-secondary" (click)="cancel()">
        <i class="fa-solid fa-times"></i>
        <span>{{'Close' | translate}}</span>
    </a>
</div>